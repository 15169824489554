/** 监控日报 */
<template>
  <div class="daily-content" ref="dailyDom">
    <ul class="notice-monitoring notice-list" v-infinite-scroll="getDailyList" infinite-scroll-disabled="disabled"
      infinite-scroll-distance="10">
      <li class="mb-10 back-fff" v-for="(item,index) in dailyData" :key="index">
        <div class="daily- justify-between-center">
          <h4 class="notice-title">监控日报（{{item.date}}）</h4>
          <el-button v-if="item.status" type="text" class="pa-0"
            @click="$router.push({path:'/personal/daily-details',query:{date:item.date}})">
            查看详情</el-button>
        </div>
        <p class="notice-con text-ellipsis">{{item.value}}</p>
      </li>
    </ul>
    <p v-if="!noMore" class="load-prompt"><i class="el-icon-loading"></i>信息加载中</p>
    <p v-if="noMore" class="load-prompt">已无加载信息</p>
  </div>
</template>
<script>
import { getDailyList } from "@/api/personal";
export default {
  name: 'Daily',
  data () {
    return {
      dailyData: [],
      noMore: false,//是否加载完成
      loading: false,// 正在加载中
      page: 1,//当前页数
    }
  },
  computed: {
    disabled () {
      return this.loading || this.noMore
    }
  },
  methods: {
    async getDailyList () {
      this.loading = true
      try {
        let { data, status } = await getDailyList(this.page)
        if (status == 200) {
          this.dailyData = [...this.dailyData, ...data]
          if (data.lengtn >= 365 || !data.length) {
            this.noMore = true
          }
          this.page += 1
          if (!this.noMore) {
            this.loading = false
          }
        }
      } catch (error) {
        return false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.notice-list {
  li {
    padding: 25px 33px;
  }
}
.notice-title {
  font-size: 18px;
  margin-bottom: 15px;
}
.notice-con {
  color: #8490ad;
}
.load-prompt {
  font-size: 14px;
  color: #999999;
  text-align: center;
  margin: 30px 0;
}
</style>